/* ========= create new character ========= */

.dw-card {
    width: 200px;
    height: 280px;
    display: inline-block;
}

.under-construction,
.selectable {
    opacity: 0.6;
    cursor: pointer;
    border: 1px solid transparent;
}

.selectable:hover {
    opacity: 1;
    background-color: #fcfcfc;
}

.selectable.active {
    color: var(--bs-list-group-color);
    background-color: #f5f5f5;
    font-weight: bold;
    border: #bfbfbf 1px solid;
    opacity: 1;
}

/* ========= game view ========= */

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-header .accordion-button {
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
}

.game-area {
    min-height: 780px;
    max-height: 900px;
    border: 3px solid transparent;
}

.game-area.almost-dead {
    border: 3px solid red;
    animation: pulsate 1.5s infinite ease-in-out;
}

@keyframes pulsate {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }
    50% {
        box-shadow: 0 0 10px 5px rgba(255, 0, 0, 0.5);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }
}

@media only screen and (max-width: 767px) {
    .game-area {
        min-height: 500px;
        max-height: 700px;
    }
}

/* ========= stats component ========= */

.stats-list-item {
    font-size: 0.875rem;
    border-bottom: 1px solid #dcdcdc;
    border-top: none;
    border-left: none;
    border-right: none;
}

.stats-list-item:last-child {
    border-bottom: none;
}

/* ========= equipment component ========= */

.equipment-list-item {
    font-size: 0.875rem;
    border-bottom: 1px solid #dcdcdc;
    border-top: none;
    border-left: none;
    border-right: none;
}

.equipment-list-item:last-child {
    border-bottom: none;
}

.equipment-list-item .btn {
    margin: 0 !important;
}

/* ========= character ========= */

.above-block {
    position: absolute;
    bottom: 104%;
    left: 0;
    width: 100%;
}

.front-block {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    z-index: 100;
}

.death-block {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 200;
    font-size: 22px;
    font-weight: bold;
}

.death-block img {
    filter: invert(1);
    width: 90%;
}

.enraged {
    transform: scale(1.2);
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.8);
}

.buff-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buff-badge {
    animation: buff-apply 500ms linear forwards;
}

.combat-target {
    width: 100%;
    font-weight: bold;
}

@keyframes buff-apply {
    0% {
        transform: scale(2) translateY(-20px);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

/* ========= navigation ========= */

.route-button {
    position: absolute;
    z-index: 1;
}

.route-button button {
    width: 34px;
    height: 34px;
    border-radius: 20px;
}

.route-north {
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.route-west {
    bottom: 50%;
    left: 5px;
    display: flex;
    flex-direction: column;
}

.route-east {
    bottom: 50%;
    right: 5px;
    display: flex;
    flex-direction: column;
}

.route-south {
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
}

.route-up {
    top: 5px;
    left: 70%;
    transform: translateX(-50%);
}

.route-up button {
    background-size: 512px 128px;
    background-position: 0 0;
    width: 128px;
    height: 128px;
    background-color: unset;
    border: none;
}

.route-up button:hover,
.route-up button:active,
.route-down button:hover,
.route-down button:active {
    background-color: unset;
}

.route-down {
    bottom: 5px;
    left: 30%;
    transform: translateX(-50%);
}

.route-down button {
    background-size: 512px 128px;
    background-position: 256px 0;
    width: 128px;
    height: 128px;
    background-color: unset;
    border: none;
}

/* ========= abilities ========= */

@keyframes cooldown-animation {
    0% {
        height: 100%;
    }
    100% {
        height: 0;
    }
}

.cooldown-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: 8px;
}

.ability {
    border: none;
    border-radius: 8px;
    height: 48px;
    width: 48px;
    position: relative;
}

.ability img {
    width: 100%;
    height: 100%;
}

.abilities .cast-bar-container {
    top: -30px;
    width: 100px;
}

.abilities .cast-bar-container .spell-name {
    color: #fff;
}

/* ========= tooltip ========= */

.custom-tooltip .tooltip-inner {
    background-color: white !important;
    color: black !important;
    border: 1px solid #ccc;
    padding: 4px 6px;
    text-align: left;
    min-width: 186px;
}

/* ========= cast bar ========= */

.cast-bar-container {
    width: 100%;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    bottom: 10px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
    animation-name: cast-wrapper;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    padding: 0 1px;
    opacity: 0;
}

.text-container {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: inline-block;
}

.spell-name {
    text-align: center;
    font-size: 9px;
}

.cast-bar {
    width: 100%;
    height: 11px;
    background-color: #f8f9fa;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    overflow: hidden;
    position: relative;
}

.cast-bar-progress {
    height: 100%;
    width: 100%;
    background-color: #dc3545;
    animation-name: cast-progress;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

@keyframes cast-progress {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes cast-wrapper {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    98% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* ========= movement animation ========= */

@keyframes move-north {
    0% {
        top: 0;
        opacity: 1;
    }
    50.001% {
        top: -300px;
        opacity: 0;
    }
    50.002% {
        top: 300px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes move-north-out {
    0% {
        top: 0;
        opacity: 1;
    }
    50%, 100% {
        top: -300px;
        opacity: 0;
    }
}

@keyframes move-north-in {
    0% {
        top: 300px;
        opacity: 0;
    }
    50%, 100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes move-south {
    0% {
        top: 0;
        opacity: 1;
    }
    50.001% {
        top: 300px;
        opacity: 0;
    }
    50.002% {
        top: -300px;
        opacity: 0;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes move-south-out {
    0% {
        top: 0;
        opacity: 1;
    }
    50%, 100% {
        top: 300px;
        opacity: 0;
    }
}

@keyframes move-south-in {
    0% {
        top: -300px;
        opacity: 0;
    }
    50%, 100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes move-west {
    0% {
        left: 0;
        opacity: 1;
    }
    50.001% {
        left: -300px;
        opacity: 0;
    }
    50.002% {
        left: 300px;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes move-west-out {
    0% {
        left: 0;
        opacity: 1;
    }
    50%, 100% {
        left: -300px;
        opacity: 0;
    }
}

@keyframes move-west-in {
    0% {
        left: 300px;
        opacity: 0;
    }
    50%, 100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes move-east {
    0% {
        left: 0;
        opacity: 1;
    }
    50.001% {
        left: 300px;
        opacity: 0;
    }
    50.002% {
        left: -300px;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes move-east-out {
    0% {
        left: 0;
        opacity: 1;
    }
    50%, 100% {
        left: 300px;
        opacity: 0;
    }
}

@keyframes move-east-in {
    0% {
        left: -300px;
        opacity: 0;
    }
    50%, 100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes move-up {
    0% {
        top: 0;
        left: 0;
        opacity: 1;
    }
    50.001% {
        top: -300px;
        left: 100px;
        opacity: 0;
    }
    50.002% {
        top: 300px;
        left: -100px;
        opacity: 0;
    }
    100% {
        top: 0;
        left: 0;
        opacity: 1;
    }
}

@keyframes move-up-out {
    0% {
        top: 0;
        left: 0;
        opacity: 1;
    }
    50%, 100% {
        top: -300px;
        left: 100px;
        opacity: 0;
    }
}

@keyframes move-up-in {
    0% {
        top: 300px;
        left: -100px;
        opacity: 0;
    }
    50%, 100% {
        top: 0;
        left: 0;
        opacity: 1;
    }
}

@keyframes move-down {
    0% {
        top: 0;
        left: 0;
        opacity: 1;
    }
    50.001% {
        top: 300px;
        left: -100px;
        opacity: 0;
    }
    50.002% {
        top: -300px;
        left: 100px;
        opacity: 0;
    }
    100% {
        top: 0;
        left: 0;
        opacity: 1;
    }
}

@keyframes move-down-out {
    0% {
        top: 0;
        left: 0;
        opacity: 1;
    }
    50%, 100% {
        top: 300px;
        left: -100px;
        opacity: 0;
    }
}

@keyframes move-down-in {
    0% {
        top: -300px;
        left: 100px;
        opacity: 0;
    }
    50%, 100% {
        top: 0;
        left: 0;
        opacity: 1;
    }
}

.move-north {
    animation: move-north 2s;
}

.move-north-out {
    animation: move-north-out 2s;
}

.move-north-in {
    animation: move-north-in 2s;
}

.move-south {
    animation: move-south 2s;
}

.move-south-out {
    animation: move-south-out 2s;
}

.move-south-in {
    animation: move-south-in 2s;
}

.move-west {
    animation: move-west 2s;
}

.move-west-out {
    animation: move-west-out 2s;
}

.move-west-in {
    animation: move-west-in 2s;
}

.move-east {
    animation: move-east 2s;
}

.move-east-out {
    animation: move-east-out 2s;
}

.move-east-in {
    animation: move-east-in 2s;
}

.move-up {
    animation: move-up 2s;
}

.move-up-out {
    animation: move-up-out 2s;
}

.move-up-in {
    animation: move-up-in 2s;
}

.move-down {
    animation: move-down 2s;
}

.move-down-out {
    animation: move-down-out 2s;
}

.move-down-in {
    animation: move-down-in 2s;
}

@keyframes move-bg {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-out {
    animation: move-bg 2s;
    z-index: 1000 !important;
}

/* ========= floating text animations ========= */

@keyframes float-up {
    0% {
        transform: translateY(-18px);
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translateY(-68px);
        opacity: 0;
    }
}

@keyframes chat-up {
    0% {
        transform: translateY(-18px);
        opacity: 1;
    }
    20% {
        transform: translateY(-33px);
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translateY(-33px);
        opacity: 0;
    }
}

@keyframes critical-float-up {
    0% {
        transform: scale(1) translateY(-18px);
        opacity: 1;
    }
    20% {
        transform: scale(1.5) translateY(-28px); /* Burst effect */
        opacity: 1;
    }
    50% {
        transform: scale(1.2) translateY(-43px);
        opacity: 0.8;
    }
    100% {
        transform: scale(1) translateY(-68px);
        opacity: 0;
    }
}

.damage {
    position: absolute;
    top: 0;
    left: 9999px;
    line-height: 18px;
    text-shadow: 1px 1px 10px #fff;
    font-size: 16px;
    color: white;
    font-weight: bold;
    z-index: 3;
    opacity: 0;
    width: 150%;
}

.hit-regular,
.hit-buff {
    animation: float-up 1.4s ease-out forwards;
}

.hit-ability {
    animation: float-up 1.4s ease-out forwards;
    top: 50px;
}

.hit-critical {
    font-size: 24px;
    color: #ffca2c;
    animation: critical-float-up 1.4s ease-out forwards;
}

.hit-mighty {
    font-size: 20px;
    animation: float-up 1.4s ease-out forwards;
}

.hit-mighty-critical {
    font-size: 24px;
    color: #ffca2c;
    animation: critical-float-up 1.4s ease-out forwards;
}

.hit-brutal {
    font-size: 22px;
    animation: float-up 1.4s ease-out forwards;
}

.hit-brutal-critical {
    font-size: 24px;
    color: #ffca2c;
    animation: critical-float-up 1.4s ease-out forwards;
}

.hit-healing {
    color: #32c07c;
    animation: float-up 1.4s ease-out forwards;
}

.hit-healing-critical {
    font-size: 24px;
    color: #32c07c;
    animation: critical-float-up 1.4s ease-out forwards;
}

.hit-skill {
    color: #0d6efd;
    animation: float-up 1.4s ease-out forwards;
    top: 50px;
}

.hit-mana {
    color: #0d6efd;
    animation: float-up 1.4s ease-out forwards;
}

.hit-miss,
.hit-dodge,
.hit-parry,
.hit-block,
.hit-resist,
.hit-absorb {
    font-weight: normal;
    animation: float-up 1.4s ease-out forwards;
}

.hit-debuff {
    font-weight: normal;
    color: #dc3545;
    animation: float-up 1.4s ease-out forwards;
}

.hit-chat {
    animation: chat-up 1.4s ease-out forwards;
    top: 20px;
    padding: 4px;
    border-radius: 15px;
    font-size: 12px;
    line-height: 12px;
    font-weight: normal;
    word-wrap: break-word;
    background-color: #d1e7dd;
    color: #0c4128;
}

/* ========= minimap ========= */

.minimap {
    width: 100%;
    height: 48%;
    min-height: 300px;
    position: relative;
}

.minimap .room {
    background-color: #6c757d;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fcfcfc;
}

/* ========= chat ========= */

.chat-container {
    height: 350px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    padding: 10px;
}

@media only screen and (max-width: 767px) {
    .chat-container {
        height: 300px;
    }
}

.chat-container input {
    box-shadow: none !important;
}

.chat-history {
    flex-grow: 1;
    overflow-y: auto;
}

.chat-history .list-group-item {
    border: none !important;
    background: none !important;
}

.chat-bubble {
    max-width: 80%;
    padding: 4px 8px;
    border-radius: 15px;
    font-size: 14px;
    word-wrap: break-word;
}

.my-message {
    background-color: #d1e7dd;
    color: #0c4128;
    border-radius: 15px 15px 0 15px;
}

.other-message {
    background-color: #f8d7da;
    color: #842029;
    border-radius: 15px 15px 15px 0;
}

